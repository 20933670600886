import { template as template_6ab8acc13daf40c5a4ecc5cbc25b3e13 } from "@ember/template-compiler";
const SidebarSectionMessage = template_6ab8acc13daf40c5a4ecc5cbc25b3e13(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
